
import Environment from '@/config/environment'
import http from '@/data/api/http'
import User from '@/data/entity/user.entity'
import authRepository from '@/data/repository/auth.repository'
import UiUtils from '@/ui/util/ui-utils'
import { Product } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import ProfileLanguageSelector from './ProfileLanguageSelector.vue'
import ProfileNewsletterSwitch from './ProfileNewsletterSwitch.vue'
import ProfilePersonalDataBlock from './ProfilePersonalDataBlock.vue'
import ProfileSubscriptionBlock from './ProfileSubscriptionBlock.vue'

@Component({
  head: {
    title: UiUtils.translateTitle('label.profile'),
  },
  components: {
    ProfilePersonalDataBlock,
    ProfileSubscriptionBlock,
    ProfileLanguageSelector,
    ProfileNewsletterSwitch,
  },
})
export default class ProfileScreen extends Vue {
  user: User | null = null

  showProducts = false
  isProductsLoading = false
  products: Product[] | null = null
  isLoadingPlanUpdatePreview = false

  isTestMode = Environment.isTest()
  isCreatingTestTrial = false

  mounted() {
    this.user = authRepository.getUser()
  }

  async createTestTrial() {
    this.isCreatingTestTrial = true
    const response = await http.post('/test/create-fake-trial')
    this.isCreatingTestTrial = false
  }
}
