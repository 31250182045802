
import Vue from 'vue'
import Component from 'vue-class-component'

import User from '@/data/entity/user.entity'
import store from '@/store/store'
import ProfileSettingsRepository from '@/data/repository/profile-settings.repository'
import { Watch } from 'vue-property-decorator'
import debounce from 'lodash.debounce'

import ProfileSocialConnections from './ProfileSocialConnections.vue'

@Component({
  components: {
    ProfileSocialConnections,
  },
})
export default class ProfilePersonalDataBlock extends Vue {
  displayName: string = ''
  isUpdatingName = false

  get user(): User | null {
    return store.state.auth.user
  }

  mounted() {
    this.onUserChange()
  }

  private updateDisplayName = debounce(async (name: string, instance: ProfilePersonalDataBlock) => {
    instance.isUpdatingName = true
    await ProfileSettingsRepository.updateDisplayName(name)
    instance.isUpdatingName = false
  }, 700)

  @Watch('displayName')
  private onDisplayNameChange() {
    const user = this.user
    if (user && this.displayName !== user.name) {
      this.updateDisplayName(this.displayName, this)
    }
  }

  @Watch('user')
  private onUserChange() {
    const user = this.user
    if (user) {
      this.displayName = user.name || ''
    }
  }
}
