
import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store/store'
import { AuthTypes } from '@/store/modules/auth.module'
import ProfileSettingsRepository from '@/data/repository/profile-settings.repository'

@Component
export default class ProfileNewsletterSwitch extends Vue {
  isUpdating = false

  get value(): boolean {
    return store.getters[AuthTypes.getters.isSubscribedToNewsletter]
  }

  async onChange(newValue: boolean) {
    this.isUpdating = true
    const result = await ProfileSettingsRepository.setSubscribedToNewsletter(newValue)
    if (result.isSuccessful) {
      if (newValue) {
        this.$snackbar.show({ message: 'You are now subscribed to our newsletter' })
      } else {
        this.$snackbar.show({ message: 'You have been unsubscribed from our newsletter' })
      }
    } else {
      this.$snackbar.show({ message: result.errorMessage })
    }
    this.isUpdating = false
  }
}
