
import PaymentRepository from '@/data/repository/payment.repository'
import { AuthTypes } from '@/store/modules/auth.module'
import store from '@/store/store'
import RouteNames from '@/ui/router/route-names'
import dayjs from 'dayjs'
import { DiscountUtils, Plan, Product, SubscriptionInfo, SubscriptionUtils } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class ProfileSubscriptionBlock extends Vue {
  isCancelingSubscription = false
  isRestoringSubscription = false

  get subscription(): SubscriptionInfo | null {
    return store.getters[AuthTypes.getters.subscription]
  }

  get product(): Product | null {
    const subscription = this.subscription
    if (subscription) {
      return subscription.product
    }
    return null
  }

  get plan(): Plan | null {
    const subscription = this.subscription
    if (subscription) {
      return subscription.plan
    }
    return null
  }

  get planDescription(): string | null {
    const subscription = this.subscription
    const product = this.product
    const plan = this.plan
    if (subscription && product && plan) {
      const price = this.getNextChargeAmount(subscription)
      return `${product.title} / $${price / 100} ${this.getIntervalString(plan)}`
    }
    return null
  }

  get discountDescription(): string | null {
    const subscription = this.subscription
    if (subscription) {
      const discount = subscription.discount
      if (discount) {
        const coupon = discount.coupon
        return `Discount: ${coupon.percentOff}% off`
      }
    }
    return null
  }

  /**
   * Return the amount that is going to be charged in the next billing cycle.
   */
  private getNextChargeAmount(sub: SubscriptionInfo): number {
    if (sub.discount) {
      if (sub.discount.end) {
        const discountEnd = dayjs(sub.discount.end)
        const nextChargeDate = dayjs(sub.nextChargeDate)
        if (nextChargeDate.isAfter(discountEnd)) {
          return sub.plan.price
        } else {
          return DiscountUtils.calculateDiscountedPrice(
            sub.plan.price,
            sub.discount.coupon.percentOff
          )
        }
      } else if (sub.discount.coupon.duration === 'forever') {
        return DiscountUtils.calculateDiscountedPrice(
          sub.plan.price,
          sub.discount.coupon.percentOff
        )
      } else {
        return sub.plan.price
      }
    } else {
      return sub.plan.price
    }
  }

  private getIntervalString(plan: Plan): string {
    const key = `interval.${plan.interval}`
    return this.$tc(key, plan.intervalCount, { count: plan.intervalCount })
  }

  get hasSubscription(): boolean {
    return SubscriptionUtils.isSubscriptionActive(this.subscription)
  }

  get canCancelSubscription(): boolean {
    return SubscriptionUtils.canCancelSubscription(this.subscription)
  }

  get canRestoreSubscription(): boolean {
    return SubscriptionUtils.canRestoreSubscription(this.subscription)
  }

  get canUpdateSubscription(): boolean {
    return SubscriptionUtils.canUpdateSubscription(this.subscription)
  }

  get isSubscriptionCanceled(): boolean {
    return this.canRestoreSubscription
  }

  get isTrialing(): boolean {
    return !!this.subscription && this.subscription.status === 'trialing'
  }

  updateSubscription() {
    this.$router.push({ name: RouteNames.PREMIUM })
  }

  cancelSubscription() {
    this.$dialog.confirm({
      title: this.$ts('action.cancelSubscription'),
      message: this.$ts('confirm.cancelSubscription'),
      onConfirm: this.doCancelSubscription,
      confirmBtnText: 'action.confirm',
      rejectBtnText: 'action.close',
      cancelable: false,
    })
  }

  private async doCancelSubscription() {
    this.isCancelingSubscription = true
    const result = await PaymentRepository.cancelSubscription()
    this.isCancelingSubscription = false
  }

  async restoreSubscription() {
    this.isRestoringSubscription = true
    const result = await PaymentRepository.restoreSubscription()
    this.isRestoringSubscription = false
  }

  selectPlan() {
    this.$router.push({ name: RouteNames.PREMIUM })
  }
}
