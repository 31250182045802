
import Vue from 'vue'
import Component from 'vue-class-component'
import SelectOption from '@/common/interfaces/select-option'
import I18n from '@/i18n/i18n'

@Component
export default class ProfileLanguageSelector extends Vue {
  isLoading = false

  languages: SelectOption[] = [
    { id: 'en', title: 'English' },
    { id: 'ru', title: 'Русский' },
  ]

  get selectedId(): string {
    return this.$i18n.locale
  }

  async onSelect(id: string) {
    this.isLoading = true
    try {
      await I18n.setLocale(id)
    } catch (err) {
      console.error(err)
    }
    this.isLoading = false
  }
}
