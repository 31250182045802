
import User from '@/data/entity/user.entity'
import store from '@/store/store'
import firebase from 'firebase/compat/app'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import AuthRepository from '@/data/repository/auth.repository'

interface AuthProvider {
  id: string
  icon: string
  title: string
  isLinked: boolean
  isLoading: boolean
}

@Component
export default class ProfileSocialConnections extends Vue {
  providers: AuthProvider[] = [
    {
      id: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      icon: 'google',
      title: 'Google',
      isLinked: false,
      isLoading: false,
    },
    {
      id: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      icon: 'facebook',
      title: 'Facebook',
      isLinked: false,
      isLoading: false,
    },
  ]

  mounted() {
    this.updateProviders()
  }

  getLabel(provider: AuthProvider): string {
    if (provider.isLinked) {
      return `Disconnect ${provider.title}`
    } else {
      return `Connect with ${provider.title}`
    }
  }

  getLinkIcon(provider: AuthProvider): string {
    return provider.isLinked ? 'unlink' : 'link'
  }

  getIconColor(provider: AuthProvider): string {
    return provider.isLinked ? 'accent' : '#000'
  }

  onClick(provider: AuthProvider) {
    if (provider.isLinked) {
      this.unlinkProvider(provider)
    } else {
      this.linkProvider(provider)
    }
  }

  private unlinkProvider(provider: AuthProvider) {
    const providers = this.user!.authProviders
    if (providers.length === 1) {
      this.$dialog.alert({
        title: 'Cannot disconnect account',
        message:
          "This is your only connected account. If you'd like to proceed, you have to connect another account first",
      })
    } else {
      this.$dialog.confirm({
        title: this.getLabel(provider),
        message: `Are you sure you want to disconnect your account from ${provider.title}?`,
        onConfirm: async () => {
          const result = await AuthRepository.unlinkProvider(provider.id)
          if (!result.isSuccessful) {
            this.$snackbar.show({ message: result.errorMessage })
          }
        },
        confirmBtnText: 'Disconnect',
      })
    }
  }

  private async linkProvider(provider: AuthProvider) {
    provider.isLoading = true
    const result = await AuthRepository.linkWithProvider(provider.id)
    provider.isLoading = false
    if (!result.isSuccessful) {
      console.error(result.errorMessage)
      this.$snackbar.show({ message: result.errorMessage })
    }
  }

  get user(): User | null {
    return store.state.auth.user
  }

  private updateProviders() {
    const user = this.user
    if (user) {
      const providerIds = user.authProviders
      this.providers.forEach(provider => {
        provider.isLinked = providerIds.includes(provider.id)
      })
    } else {
      this.providers.forEach(provider => (provider.isLinked = false))
    }
  }

  @Watch('user')
  private onUserChanged() {
    this.updateProviders()
  }
}
