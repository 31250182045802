import { Collection, ProfileSettings } from 'shared-entities'
import { OperationError, OperationResult, OperationSuccess } from '../api/operation-result'
import asyncFirestore from '../firebase/async-firestore'
import AuthRepository from './auth.repository'

export default class ProfileSettingsRepository {
  static async updateDisplayName(name: string): Promise<void> {
    try {
      const user = AuthRepository.getUser()
      if (user && user.firebaseUser) {
        await user.firebaseUser.updateProfile({
          displayName: name ? name : null,
        })
        AuthRepository.refreshFirebaseUser()
      }
    } catch (error) {
      console.error(error)
    }
  }

  static async setSubscribedToNewsletter(
    subscribed: boolean
  ): Promise<OperationResult<ProfileSettings>> {
    try {
      const user = AuthRepository.getUser()
      if (user && user.customer) {
        const firestore = await asyncFirestore()
        const docRef = firestore.collection(Collection.CUSTOMERS).doc(user.id)
        await docRef.update({ 'settings.subscribedToNewsletter': subscribed })
        const newSettings = (await docRef.get({ source: 'cache' })).data()!.settings
        return new OperationSuccess(newSettings)
      } else {
        return new OperationError({ errorMessage: 'User not logged in' })
      }
    } catch (error) {
      return OperationError.fromError(error)
    }
  }
}
